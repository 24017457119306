import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ICvAnalyzeItem } from 'src/types/cv-analyzer';

import type { RootState } from '../store';

import {
  getCvAnalyzeDetails,
  getCvAnalyzeList,
  uploadCVsToCvAnalyze,
  createCvAnalyze,
} from '../actions/cvAnalyzer.actions';

// Define an exportable state type interface for job status
export type JobStatusType = 'idle' | 'loading' | 'success' | 'failed';

// Define de type for the initial state
interface ICvAnalyzerState {
  status: JobStatusType;
  error: any;
  cvAnalyzeList: ICvAnalyzeItem[];
  selectedCvAnalyze: ICvAnalyzeItem | undefined;
  message: string | null;
}

// Define the initial state using that type
const initialState: ICvAnalyzerState = {
  status: 'idle',
  error: null,
  cvAnalyzeList: [],
  selectedCvAnalyze: undefined,
  message: null,
};

export const cvAnalyzerSlice = createSlice({
  name: 'cvAnalyzer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearSuccessMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createCvAnalyze.pending, (state: ICvAnalyzerState) => {
      state.status = 'loading';
    });
    builder.addCase(
      createCvAnalyze.fulfilled,
      (state: ICvAnalyzerState, action: PayloadAction<ICvAnalyzeItem>) => {
        state.cvAnalyzeList.push(action.payload);
        state.status = 'success';
        state.message = 'CV Analyze created successfully';
      }
    );
    builder.addCase(createCvAnalyze.rejected, (state: ICvAnalyzerState, action: any) => {
      state.status = 'failed';
      state.error = action.error;
    });
    builder.addCase(getCvAnalyzeList.pending, (state: ICvAnalyzerState) => {
      state.status = 'loading';
    });
    builder.addCase(
      getCvAnalyzeList.fulfilled,
      (state: ICvAnalyzerState, action: PayloadAction<Array<ICvAnalyzeItem>>) => {
        if (action.payload) {
          state.cvAnalyzeList = action.payload;
          state.status = 'success';
        }
      }
    );
    builder.addCase(getCvAnalyzeList.rejected, (state: ICvAnalyzerState, action: any) => {
      state.status = 'failed';
      state.error = action.error;
    });
    builder.addCase(getCvAnalyzeDetails.pending, (state: ICvAnalyzerState) => {
      state.status = 'loading';
    });
    builder.addCase(
      getCvAnalyzeDetails.fulfilled,
      (state: ICvAnalyzerState, action: PayloadAction<ICvAnalyzeItem>) => {
        state.selectedCvAnalyze = action.payload;
        state.status = 'success';
      }
    );
    builder.addCase(getCvAnalyzeDetails.rejected, (state: ICvAnalyzerState, action: any) => {
      state.status = 'failed';
      state.error = action.error;
    });
    builder.addCase(uploadCVsToCvAnalyze.pending, (state: ICvAnalyzerState) => {
      state.status = 'loading';
    });
    builder.addCase(
      uploadCVsToCvAnalyze.fulfilled,
      (state: ICvAnalyzerState, action: PayloadAction<any>) => {
        if (state.selectedCvAnalyze && action.payload && action.payload.cvFiles) {
          // Filter out new CVs that already exist in the `cvs` array
          const newCVs = action.payload.cvFiles.filter(
            (newCv: string) =>
              !state.selectedCvAnalyze!.cvs.some((existingCv) => existingCv.originalName === newCv)
          );

          // Add only the new CVs to the `cvs` array as objects
          newCVs.forEach((cvName: string) => {
            state.selectedCvAnalyze!.cvs.push({ originalName: cvName, status: 'pending' });
          });
        }

        state.status = 'success';
        state.message = 'CVs uploaded successfully';
      }
    );

    builder.addCase(uploadCVsToCvAnalyze.rejected, (state: ICvAnalyzerState, action: any) => {
      state.status = 'failed';
      state.error = action.error;
    });
  },
});

export const { actions } = cvAnalyzerSlice;

export const { clearError, clearSuccessMessage } = actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.value;

export default cvAnalyzerSlice.reducer;
