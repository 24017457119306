import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { clearError as clearCandidateError } from 'src/store/reducers/CandidateSlice';
import { clearError as clearJobError } from 'src/store/reducers/JobsSlice';
import { clearError as clearUserError } from 'src/store/reducers/userSlice';
import { clearError as clearCvAnalyzeError } from 'src/store/reducers/CvAnalyzerSlice';

const useErrorHandling = () => {
  const candidateError = useAppSelector((state) => state.candidate.error);
  const jobError = useAppSelector((state) => state.jobs.error);
  const userError = useAppSelector((state) => state.user.error);
  const cvAnalyzeError = useAppSelector((state) => state.cvAnalyzer.error);

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(clearCandidateError());
    dispatch(clearJobError());
    dispatch(clearUserError());
    dispatch(clearCvAnalyzeError());
  }, [dispatch]);

  useEffect(() => {
    const handleCloseEffect = () => {
      if (candidateError || jobError || userError || cvAnalyzeError) {
        const timer = setTimeout(() => {
          handleClose();
        }, 10000);

        return () => clearTimeout(timer);
      }
      return true;
    };

    handleCloseEffect(); // Explicit return statement
  }, [candidateError, jobError, userError, cvAnalyzeError, handleClose]);

  const error = candidateError || jobError || userError || cvAnalyzeError;
  return { error: error || null, handleClose }; // Return an object with error and handleClose properties
};

export default useErrorHandling;
