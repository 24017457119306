import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { endpoints as END_POINTS } from 'src/utils/axios';

export const candidateLanding = createAsyncThunk(
  '/candidateLanding',
  // eslint-disable-next-line consistent-return
  async (token: string, thunkAPI) => {
    try {
      // make API call
      const response = await axios.get(END_POINTS.candidate.landing(token));

      return response.data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const candidateGetJobData = createAsyncThunk(
  '/candidateGetJobData',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.get(END_POINTS.candidate.candidateGetJobData(jobId));

      return response.data.data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const approveInterview = createAsyncThunk(
  '/approveInterview',
  // eslint-disable-next-line consistent-return
  async (data: any, thunkAPI) => {
    try {
      const { jobId, approvalData } = data;
      const response = await axios.post(END_POINTS.candidate.approveRejectJob(jobId), approvalData);

      return data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const rejectInterview = createAsyncThunk(
  '/rejectInterview',
  // eslint-disable-next-line consistent-return
  async (data: any, thunkAPI) => {
    try {
      const { jobId, approvalData } = data;
      const response = await axios.post(END_POINTS.candidate.approveRejectJob(jobId), approvalData);

      return data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const initiateInterview = createAsyncThunk(
  '/initiateInterview',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.post(END_POINTS.candidate.initiateInterview(jobId));

      return response.data.data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const finishInterview = createAsyncThunk(
  '/finishInterview',
  // eslint-disable-next-line consistent-return
  async (jobId: string, thunkAPI) => {
    try {
      const response = await axios.post(END_POINTS.candidate.finishInterview(jobId));

      return response.data.data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const sendInterviewMessage = createAsyncThunk(
  '/sendInterviewMessage',
  // eslint-disable-next-line consistent-return
  async (data: { jobId: string; message: string }, thunkAPI) => {
    try {
      const { jobId, message } = data;
      const response = await axios.post(END_POINTS.candidate.sendInterviewMessage(jobId), {
        message,
      });

      const aiResponse = response.data.data;

      const returnData = {
        message,
        aiResponse,
      };

      return returnData;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const sendVoiceMessage = createAsyncThunk(
  '/sendVoiceMessage',
  // eslint-disable-next-line consistent-return
  async (data: { jobId: string; audioBlob: Blob }, thunkAPI) => {
    try {
      const { jobId, audioBlob } = data;
      const formData = new FormData();
      formData.append('audio', audioBlob, 'recording.wav');

      const response = await axios.post(END_POINTS.candidate.sendVoiceMessage(jobId), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const applyPublicJob = createAsyncThunk(
  '/applyPublicJob',
  // eslint-disable-next-line consistent-return
  async (data: any, thunkAPI) => {
    try {
      const response = await axios.post(END_POINTS.candidate.applyPublicJob(data.jobId), data);

      return response.data;
    } catch (e: any) {
      console.log('candidate actions - error: ', e);
    }
  }
);

export const submitQuestionAnswers = createAsyncThunk(
  '/submitQuestionAnswers',
  // eslint-disable-next-line consistent-return
  async (data: any, thunkAPI) => {
    try {
      await axios.post(END_POINTS.candidate.submitQuestionAnswers(data.jobId), {
        answers: data.answers,
      });

      return data.answers;
    } catch (e: any) {
      console.log('jobs actions - error: ', e);
      throw e;
    }
  }
);
