import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { endpoints as END_POINTS } from 'src/utils/axios';

export const createCvAnalyze = createAsyncThunk('/createJob', async (data: FormData, thunkAPI) => {
  try {
    // Send the FormData directly without manually setting the Content-Type
    const response = await axios.post(END_POINTS.cvAnalyzer.create, data);

    return response.data; // Ensure this matches the actual response structure
  } catch (e: any) {
    console.error(' actions - error: ', e);
    throw e;
  }
});

export const getCvAnalyzeList = createAsyncThunk(
  '/getCvAnalyzeList',
  // eslint-disable-next-line consistent-return
  async (_: void, thunkAPI) => {
    try {
      // make API call to get customer data
      const response = await axios.get(END_POINTS.cvAnalyzer.list);

      return response.data.data;
    } catch (e: any) {
      console.log('actions - error: ', e);
    }
  }
);

export const getCvAnalyzeDetails = createAsyncThunk(
  '/getCvAnalyzeDetails',
  // eslint-disable-next-line consistent-return
  async (cvAnalyzeId: string, thunkAPI) => {
    try {
      const response = await axios.get(END_POINTS.cvAnalyzer.getCvAnalyzeDetails(cvAnalyzeId));

      return response.data.data;
    } catch (e: any) {
      console.log('getCvAnalyzeDetails actions - error: ', e);
    }
  }
);

export const uploadCVsToCvAnalyze = createAsyncThunk(
  '/uploadCVsToCvAnalyze',
  async (data: FormData, thunkAPI) => {
    try {
      // Extract the FormData into a structured object
      const payload = {
        cvAnalyzeId: data.get('cvAnalyzeId'),
        cvFiles: [] as string[],
      };

      // Iterate through FormData and collect file names
      data.forEach((value, key) => {
        if (key === 'cvFiles' && value instanceof File) {
          payload.cvFiles.push(value.name); // Collect the file names
        }
      });

      // Send the FormData directly
      await axios.post(END_POINTS.cvAnalyzer.uploadCVs(payload.cvAnalyzeId as string), data);

      return payload; // Return the structured object for the reducer
    } catch (error: any) {
      console.error('[ACTION] - uploadCVsToCvAnalyze - error: ', error);
      throw error;
    }
  }
);
