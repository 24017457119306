import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { clearSuccessMessage as clearCandidateSuccess } from 'src/store/reducers/CandidateSlice';
// import { clearError as clearJobError } from 'src/store/reducers/JobsSlice';
import { clearSuccessMessage as clearUserSuccess } from 'src/store/reducers/userSlice';
import { clearSuccessMessage as clearJobSuccess } from 'src/store/reducers/JobsSlice';
import { clearSuccessMessage as clearCvAnalyzeSuccess } from 'src/store/reducers/CvAnalyzerSlice';

const useSuccessHandling = () => {
  const candidateSuccess = useAppSelector((state) => state.candidate.message);
  // const jobError = useAppSelector((state) => state.jobs.error);
  const userSuccess = useAppSelector((state) => state.user.message);
  const jobSuccess = useAppSelector((state) => state.jobs.message);
  const cvAnalyzeSuccess = useAppSelector((state) => state.cvAnalyzer.message);

  const dispatch = useAppDispatch();

  const handleSuccessClose = useCallback(() => {
    dispatch(clearUserSuccess());
    dispatch(clearJobSuccess());
    dispatch(clearUserSuccess());
    dispatch(clearCandidateSuccess());
    dispatch(clearCvAnalyzeSuccess());
  }, [dispatch]);

  useEffect(() => {
    const handleCloseEffect = () => {
      if (userSuccess || jobSuccess || candidateSuccess || cvAnalyzeSuccess) {
        const timer = setTimeout(() => {
          handleSuccessClose();
        }, 10000);

        return () => clearTimeout(timer);
      }
      return true;
    };

    handleCloseEffect(); // Explicit return statement
  }, [userSuccess, jobSuccess, candidateSuccess, cvAnalyzeSuccess, handleSuccessClose]);

  const success = userSuccess || jobSuccess || candidateSuccess || cvAnalyzeSuccess;
  return { success: success || null, handleSuccessClose }; // Return an object with error and handleClose properties
};

export default useSuccessHandling;
