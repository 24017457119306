import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';

import { useLocales } from 'src/locales';

// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  cv: icon('ic_cv'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // HR The AI
      // ----------------------------------------------------------------------
      {
        subheader: 'Collo AI',
        items: [
          // DASHBOARD
          {
            title: t('dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
            roles: ['admin', 'employer', 'candidate'],
          },
          // JOB Module for employers
          {
            title: t('interviews'),
            path: paths.dashboard.job.root,
            icon: ICONS.job,
            children: [
              { title: t('list'), path: paths.dashboard.job.root },
              { title: t('create'), path: paths.dashboard.job.new },
            ],
            roles: ['admin', 'employer'],
          },
          {
            title: t('talentAnalyzer'),
            path: paths.dashboard.talentAnalyzer.root,
            icon: ICONS.file,
            children: [
              { title: t('list'), path: paths.dashboard.talentAnalyzer.root },
              { title: t('create'), path: paths.dashboard.talentAnalyzer.new },
            ],
            roles: ['admin', 'employer'],
          },
          // JOB Module for candidates
          {
            title: t('interviews'),
            path: paths.dashboard.job.root,
            icon: ICONS.job,
            children: [{ title: t('list'), path: paths.dashboard.job.root }],
            roles: ['candidate'],
          },
          // REPORT
          {
            title: t('analytics'),
            path: paths.dashboard.analytics.candidates,
            icon: ICONS.analytics,
            children: [
              { title: t('jobs'), path: paths.dashboard.analytics.jobs, disabled: true },
              {
                title: t('interviews'),
                path: paths.dashboard.analytics.interviews,
                disabled: true,
              },
              {
                title: t('candidates'),
                path: paths.dashboard.analytics.candidates,
                disabled: true,
              },
              { title: t('costs'), path: paths.dashboard.analytics.costs, disabled: true },
            ],
            roles: ['employer', 'admin'],
          },
          {
            title: 'profile',
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              { title: 'Overview', path: paths.dashboard.user.root },
              { title: 'Security', path: paths.dashboard.company.root, disabled: true },
              { title: 'Settings', path: paths.dashboard.company.root, disabled: true },
            ],
            roles: ['employer', 'candidate', 'admin'],
          },
          {
            title: 'company',
            path: paths.dashboard.company.root,
            icon: ICONS.banking,
            children: [
              { title: 'Overview', path: paths.dashboard.company.root },
              { title: 'Users', path: paths.dashboard.company.root, disabled: true },
              { title: 'Billing', path: paths.dashboard.company.root, disabled: true },
            ],
            roles: ['employer', 'admin'],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
