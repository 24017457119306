import axios, { AxiosRequestConfig } from 'axios';
import { create } from 'lodash';
// config
import { HOST_API } from 'src/config-global';

import { list } from 'src/theme/overrides/components/list';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/auth/sign-in-with-token',
    login: '/auth/login',
    register: '/auth/sign-up',
    confirmAccount: (token: string) => `/auth/confirm-account/${token}`,
    waitingList: 'auth/waiting-list',
  },
  job: {
    list: '/job/host/getJobList',
    listForCandidate: '/job/candidate/job-list',
    create: '/job/create',
    edit: (jobId: string) => `/job/edit/${jobId}`,
    publish: (jobId: string) => `/job/publishJob/${jobId}`,
    getJobDetailsWithCandidates: (jobId: string) => `/job//detailed-list/${jobId}`,
    getCandidateList: (jobId: string) => `/job/candidate-list/${jobId}`,
    getJobData: (jobId: string) => `/job/host/${jobId}`,
    inviteCandidate: (jobId: string) => `/job/inviteCandidate/${jobId}`,
    removeCandidate: (candidateJobId: string) => `/job/remove-candidate/${candidateJobId}`,
    letAiDecide: (jobId: string) => `/job/let-ai-decide/${jobId}`,
  },
  candidate: {
    landing: (token: string) => `/candidate/landing?token=${token}`,
    candidateGetJobData: (jobId: string) => `/candidate/job-details/${jobId}`,
    approveRejectJob: (jobId: string) => `/candidate/interview-approval/${jobId}`,
    initiateInterview: (jobId: string) => `/candidate/initiate-interview/${jobId}`,
    sendInterviewMessage: (jobId: string) => `/candidate/send-message/${jobId}`,
    sendVoiceMessage: (jobId: string) => `/candidate/send-voice-message/${jobId}`,
    finishInterview: (jobId: string) => `/candidate/finish-interview/${jobId}`,
    applyPublicJob: (jobId: string) => `/candidate/apply-public-job/${jobId}`,
    submitQuestionAnswers: (jobId: string) => `/candidate/submit-question-answers/${jobId}`,
  },
  chat: '/api/chat',
  user: {
    updateAccount: '/user/profile',
    updatePassword: '/user/update-password',
    sendConfirmationLink: '/user/resend-confirmation-email',
  },
  fraudDetection: {
    activity: '/fraud-detection/activity',
  },
  cvAnalyzer: {
    create: '/cv-analyzer/create',
    list: '/cv-analyzer/list',
    getCvAnalyzeDetails: (cvAnalyzeId: string) => `/cv-analyzer/${cvAnalyzeId}`,
    uploadCVs: (cvAnalyzeId: string) => `/cv-analyzer/upload/${cvAnalyzeId}`,
  },
};
