// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://colloAI.com',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      candidateLanding: `${ROOTS.AUTH}/jwt/candidate-landing`,
      applyPublic: `${ROOTS.AUTH}/jwt/apply-public-job/`,
      confirmAccount: `${ROOTS.AUTH}/confirm-account?token=${':token'}`,
      waitingList: `${ROOTS.AUTH}/jwt/waiting-list`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    company: {
      root: `${ROOTS.DASHBOARD}/company`,
      users: `${ROOTS.DASHBOARD}/company/users`,
      billing: `${ROOTS.DASHBOARD}/company/billing`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user/account`,
      // new: `${ROOTS.DASHBOARD}/user/new`,
      // list: `${ROOTS.DASHBOARD}/user/list`,
      // cards: `${ROOTS.DASHBOARD}/user/cards`,
      // profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account-details`,
      // edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      // interview: (jobId: string, interviewId: string) =>
      //   `${ROOTS.DASHBOARD}/job/${jobId}/interview/${interviewId}`,
    },
    interview: {
      // root: `${ROOTS.DASHBOARD}/interview`,
      details: (id: string) => `${ROOTS.DASHBOARD}/interview/${id}`,
    },
    candidate: {
      root: `${ROOTS.DASHBOARD}/candidate`,
      details: (id: string) => `${ROOTS.DASHBOARD}/candidate/job-details/${id}`,
    },
    talentAnalyzer: {
      root: `${ROOTS.DASHBOARD}/talent-analyzer`,
      new: `${ROOTS.DASHBOARD}/talent-analyzer/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/talent-analyzer/${id}`,
    },

    chat: `${ROOTS.DASHBOARD}/chat`,
    analytics: {
      jobs: `${ROOTS.DASHBOARD}/analytics/jobs`,
      interviews: `${ROOTS.DASHBOARD}/analytics/interviews`,
      candidates: `${ROOTS.DASHBOARD}/analytics/candidates`,
      costs: `${ROOTS.DASHBOARD}/analytics/costs`,
    },
  },
};
